import { useMemo } from 'react';
import buildCloudinaryImgAttributes from './buildCloudinaryImgAttributes';
import { ImgAttributes } from './types';

export default function useCloudinaryImgAttributes(
  url: string,
  width: number,
  additionalTransformations?: string[],
): ImgAttributes {
  return useMemo(() => {
    return buildCloudinaryImgAttributes(url, width, additionalTransformations);
  }, [additionalTransformations, url, width]);
}
