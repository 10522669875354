import { isCloudinaryUrl } from './utils';
import { ImgAttributes } from './types';

/**
 * Returns Cloudinary-driven src and srcSet attributes, optimizing images according to user-supported pixel density and provided widths. For examples of usage, check the `README.md` in this folder.
 *
 * @param url - The image URL to transform
 * @param alt - The image alt text
 * @param width - The width to use for image optimization. Can either be A) a number or B) an array of objects containing conditions and image sizes.
 * @param additionalTransformations - Additional transformations to pass to every image
 * @returns The Cloudinary-driven src and srcSet attributes
 *
 */
export default function buildCloudinaryImgAttributes(
  url: string,
  width: number | null,
  additionalTransformations: string[] | undefined = [],
): ImgAttributes {
  if (!isCloudinaryUrl(url)) {
    return {
      src: url,
    };
  }

  const transformations = [...additionalTransformations, ...(width ? [`w_${width}`] : [])];

  return {
    src: `${url}?tx=${transformations.join(',')}`,
    srcSet: `${url}?tx=${[...transformations, 'dpr_2.0'].join(',')} 2x`,
  };
}
