import IconBlogger from '@iabbb/icons/Blogger';
import IconFacebook from '@iabbb/icons/Facebook';
import IconFlickr from '@iabbb/icons/Flickr';
import IconFoursquare from '@iabbb/icons/Foursquare';
import IconGooglePlus from '@iabbb/icons/GooglePlus';
import IconInstagram from '@iabbb/icons/Instagram';
import IconLinkedIn from '@iabbb/icons/LinkedIn';
import IconMyspace from '@iabbb/icons/Myspace';
import IconPinterest from '@iabbb/icons/Pinterest';
import IconReddit from '@iabbb/icons/Reddit';
import IconSnapchat from '@iabbb/icons/Snapchat';
import IconStumbleUpon from '@iabbb/icons/StumbleUpon';
import IconTumblr from '@iabbb/icons/Tumblr';
import IconTwitter from '@iabbb/icons/Twitter';
import IconVimeo from '@iabbb/icons/Vimeo';
import IconVine from '@iabbb/icons/Vine';
import IconWeebly from '@iabbb/icons/Weebly';
import IconWikihow from '@iabbb/icons/Wikihow';
import IconWikipedia from '@iabbb/icons/Wikipedia';
import IconWordPress from '@iabbb/icons/WordPress';
import IconYouTube from '@iabbb/icons/YouTube';

export enum SocialMediaType {
  Blogger = 'blogger',
  Facebook = 'facebook',
  Foursquare = 'foursquare',
  Flickr = 'flickr',
  Google = 'google',
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
  Myspace = 'myspace',
  Pinterest = 'pinterest',
  Reddit = 'reddit',
  Snapchat = 'snapchat',
  StumbleUpon = 'stumbleupon',
  Twitter = 'twitter',
  Tumblr = 'tumblr',
  Vimeo = 'vimeo',
  Vine = 'vine',
  Weebly = 'weebly',
  Wikihow = 'wikihow',
  Wikipedia = 'wikipedia',
  WordPress = 'wordpress',
  YouTube = 'youtube',
}

export type SocialMedia = { icon: React.FC<any> };

export const SocialMediaByType: { [K in SocialMediaType]: SocialMedia } = {
  [SocialMediaType.Blogger]: { icon: IconBlogger },
  [SocialMediaType.Facebook]: { icon: IconFacebook },
  [SocialMediaType.Flickr]: { icon: IconFlickr },
  [SocialMediaType.Foursquare]: { icon: IconFoursquare },
  [SocialMediaType.Google]: { icon: IconGooglePlus },
  [SocialMediaType.Instagram]: { icon: IconInstagram },
  [SocialMediaType.LinkedIn]: { icon: IconLinkedIn },
  [SocialMediaType.Myspace]: { icon: IconMyspace },
  [SocialMediaType.Pinterest]: { icon: IconPinterest },
  [SocialMediaType.Reddit]: { icon: IconReddit },
  [SocialMediaType.Snapchat]: { icon: IconSnapchat },
  [SocialMediaType.StumbleUpon]: { icon: IconStumbleUpon },
  [SocialMediaType.Tumblr]: { icon: IconTumblr },
  [SocialMediaType.Twitter]: { icon: IconTwitter },
  [SocialMediaType.Vimeo]: { icon: IconVimeo },
  [SocialMediaType.Vine]: { icon: IconVine },
  [SocialMediaType.Weebly]: { icon: IconWeebly },
  [SocialMediaType.Wikihow]: { icon: IconWikihow },
  [SocialMediaType.Wikipedia]: { icon: IconWikipedia },
  [SocialMediaType.WordPress]: { icon: IconWordPress },
  [SocialMediaType.YouTube]: { icon: IconYouTube },
};
